*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    color:white;
}
.contact-info {
    text-align: center;
    padding: 50px 0;
    background-color: #222;
    height: 78vh;
}

.contact-info h2 {
    margin-bottom: 20px;
    background-color: #222;
}
/* body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    /* background-color: #333; 
}

.contact-form {
    padding: 40px 0;
    margin: 0 10px;
    background-color: #333;
}

.form-container {
    max-width: 40%;
    margin: 0 auto;
    padding: 20px;
    background-color: #333;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
    /* padding-bottom: 20px; 
}

.form-group {
    margin-bottom: 20px;
}

.form-container label {
    display: block;
    font-weight: bold;
}

.form-container input,
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    resize: vertical;
} */

/* .submit-button {
    padding: 10px 20px;
    background-color: #0dac30;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
} */
