.main{
    background-color: #222;
    /* height: 140vh; */
}
@media (max-width:768px) {
    .main{
        height: 100%;
    }
}

.careerBody{
    min-height:78vh;
}